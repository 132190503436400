import React, {useEffect, useState} from 'react';
import patients from '../../adapters/patient/getPatients';
// COMPONENTS
import Tabs from '../../components/Tabs/Tabs';
import AddPatientForm from '../../components/Forms/AddPatientForm';
import PlanedVisitsList from '../../components/VisitsList/PlanedVisitsList';
import VistiListByTreatment from '../../components/VisitsList/VisitsListByTreatment';
import TreatmentForm from '../../components/TreatmentForm/TreatmentForm';
import Gallery from '../Gallery/Gallery';
import {Row, Col, Accordion, Card, Button} from 'react-bootstrap';
import AddFile from '../AddFile/AddFile';
// INTERFACES
import IPatient from '../../types/patientInterface';
import {ITreatment} from '../../types/treatmentInterface';
import TreatmentList from '../../components/TreatmentList/TreatmentList';

export interface ITreatmentProps {
	treatment?: ITreatment;
	patientId: number;
}

const Treatment = (props: ITreatmentProps) => {
	const [patient, setPatient] = useState<IPatient | undefined>();
	const [newImage, setNewImage] = useState();
	const [newAgreement, setNewAgreement] = useState();

	useEffect(() => {
		patients(props.patientId).then((response) => {
			setPatient(response as IPatient);
		});
		return () => {};
	}, []);

	return (
		<div>
			<Tabs tabs={['Dane pacjenta', 'Historia leczeń', 'Zdjęcia i pliki', 'Informacje i zgody']}>
				<div className='container__tab active' data-tab='Dane pacjenta'>
					{patient && <AddPatientForm isPopUp={false} patient={patient} />}
					{props.treatment && <TreatmentForm treatment={props.treatment} />}
					{props.treatment && (
						<VistiListByTreatment
							patientId={props.patientId}
							treatmentId={props.treatment.id}
						></VistiListByTreatment>
					)}
				</div>

				<div className='container__tab' data-tab='Historia leczeń'>
					<PlanedVisitsList patientId={props.patientId} />
					{patient && patient.id && <TreatmentList patientId={patient.id} />}
				</div>

				<div className='container__tab' data-tab='Zdjęcia i pliki'>
					{patient && patient.id && (
						<AddFile onAdd={setNewImage} isAgreement={false} patientId={patient.id} />
					)}
					{patient && patient.id && (
						<Gallery newImage={newImage} patientId={patient.id} isAgreement={false} />
					)}
				</div>

				<div className='container__tab' data-tab='Informacje i zgody'>
					{patient && patient.id && (
						<AddFile onAdd={setNewAgreement} isAgreement={true} patientId={patient.id} />
					)}
					{patient && patient.id && (
						<Gallery newImage={newAgreement} patientId={patient.id} isAgreement={true} />
					)}
				</div>
			</Tabs>
		</div>
	);
};

export default Treatment;
