import React, {useEffect, useState} from 'react';
import {Button, Card, Form} from 'react-bootstrap';
// MODULES
import {connect} from 'react-redux';
import addVisit from '../../adapters/visit/addVisit';
import visitById from '../../adapters/visit/getById';
import date from '../Calendar/helpers/date';
import updateVisit from '../../adapters/visit/updateVisit';
import moment from 'moment';
import {notificationActions} from '../../redux/notifications/index';
import getPatinet from '../../adapters/patient/getPatients';
import userNames from '../../adapters/user/userNames';
import getCodes from '../../adapters/therapy/getCodes';
// TYPES
import IPatient from '../../types/patientInterface';
import {IFullVisit} from '../../types/visitInterface';
// COMPONENTS
import TimePicker from 'rc-time-picker';
import CreatableSelect from 'react-select/creatable';
// STYLES
import 'rc-time-picker/assets/index.css';
import {values} from 'lodash';
import {OptionsType} from 'react-select';

export interface IAddVisitProps {
	userId: number;
	visitId: number;
	date: Date;
	duration: number;
	start: string;
	addNotification: any;
	userPermissions: string;
}

const AddVisit = (props: IAddVisitProps) => {
	const [patient, setPatient] = useState<IPatient | null>(null);
	const [visit, setVisit] = useState<IFullVisit | undefined>();
	const [isVisitsLoading, setIsVisitsLoading] = useState(true);
	const [user, setUser] = useState<{name: string; lastname: string} | undefined>(undefined);
	const [codes, setCodes] = useState<string[]>(new Array());

	useEffect(() => {
		visitById(props.visitId)
			.then((res) => {
				//console.log(res);
				setVisit(res as IFullVisit);
				setIsVisitsLoading(false);
				getPatinet((res as IFullVisit).patient_id).then((result) => {
					setPatient(result as IPatient);
				});

				userNames((res as IFullVisit).user_id).then((response) => {
					if (response.user) setUser(response.user);
				});
			})
			.catch((e) => {
				setIsVisitsLoading(false);
			});

		getCodes().then((response) => {
			//console.log(response);
			setCodes(response as string[]);
		});
	}, []);

	if (visit && visit.id == 77) {
		// //console.log(visit.codes);
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (visit) {
			if (event.currentTarget.type === 'checkbox') {
				setVisit({
					...visit,
					[event.currentTarget.name]: event.currentTarget.checked,
				});
			} else {
				setVisit({
					...visit,
					[event.currentTarget.name]: event.currentTarget.value,
				});
			}
		}
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		if (visit && visit.id) {
			updateVisit({
				...visit,
				visit_duration: Number(visit.visit_duration),
				patient_id: Number(visit.patient_id),
				user_id: props.userId,
			})
				.then((response) => {
					props.addNotification(response.message);
				})
				.catch((e) => props.addNotification(e.message));
		}
	};

	const prepareOptions = (codes: string[]): {value: string; label: string}[] => {
		return codes.map((code) => {
			return {
				value: code,
				label: code,
				clearableValue: true,
			};
		});
	};

	const handleCodeChange = (values: OptionsType<{label: string; value: string}>) => {
		if (visit) {
			setVisit({...visit, codes: values.map((value) => value.value)});
		}
	};

	if (visit) {
		return (
			<Card className={`w-100 ${isVisitsLoading ? 'loading' : ''}`}>
				<Card.Header>
					<b>Wizyta:</b> {visit.date} {visit.start_time} <b>Status:</b>{' '}
					{visit.done ? 'Odbyta' : visit.confirmed ? 'Potwierdzona' : 'Zaplanowana'}
				</Card.Header>
				<Card.Body>
					<Form onSubmit={handleSubmit} className='d-flex flex-wrap'>
						{user && (
							<div className='w-50 px-3 mb-3'>
								<b>Fizjoterapeuta:</b>
								<br /> {user.name} {user.lastname}
							</div>
						)}
						<Form.Group className='d-flex flex-wrap w-50 px-3 mb-3'>
							<Form.Label className='w-100'>Pacjent</Form.Label>
							<Form.Control
								type='text'
								value={`${patient ? patient.lastname : ''} ${patient ? patient.name : ''}`}
								disabled
							/>
						</Form.Group>
						<Form.Group controlId='formBasicPassword' className='w-50 px-3 mb-3'>
							<Form.Label>Data wizyty</Form.Label>
							<Form.Control
								type='date'
								name='date'
								onChange={handleChange}
								value={visit.date}
								required
								disabled
							/>
						</Form.Group>
						<Form.Group controlId='formBasicPassword' className='w-50 px-3 mb-3'>
							<Form.Label>Od</Form.Label>
							<TimePicker
								value={moment(visit.start_time.slice(0, 5), 'HH:mm')}
								minuteStep={5}
								onChange={(value) => {
									setVisit({...visit, start_time: value.format('HH:mm')});
								}}
								showSecond={false}
								className='w-100'
								allowEmpty={false}
								disabled
							/>
						</Form.Group>
						<Form.Group controlId='formBasicPassword' className='w-50 px-3 mb-3'>
							<Form.Label className='required'>Czas trwania</Form.Label>
							<Form.Control
								type='number'
								placeholder='45'
								name='visit_duration'
								value={visit.visit_duration}
								onChange={handleChange}
								step={5}
								required
								disabled
							/>
						</Form.Group>
						<Form.Group controlId='exampleForm.ControlTextarea1' className='w-50 px-3 mb-3'>
							<Form.Label>Opis</Form.Label>
							<Form.Control
								as='textarea'
								rows={4}
								name='description'
								onChange={handleChange}
								value={visit.description}
							/>
						</Form.Group>
						<Form.Group controlId='exampleForm.ControlTextarea2' className='w-50 px-3 mb-3'>
							<Form.Label>Wywiad</Form.Label>
							<Form.Control
								as='textarea'
								rows={4}
								name='interview'
								onChange={handleChange}
								value={visit.interview}
							/>
						</Form.Group>
						<Form.Group controlId='exampleForm.ControlTextarea1' className='w-50 px-3 mb-3'>
							<Form.Label>Badania funkcjonalne</Form.Label>
							<Form.Control
								as='textarea'
								rows={4}
								name='research'
								onChange={handleChange}
								value={visit.research}
							/>
						</Form.Group>
						{/* <Form.Group controlId='exampleForm.ControlTextarea1' className='w-50 px-3 mb-3'>
							<Form.Label>Plan terapii</Form.Label>
							<Form.Control
								as='textarea'
								rows={4}
								name='plan'
								onChange={handleChange}
								value={visit.plan}
							/>
						</Form.Group> */}
						{visit && (
							<Form.Group controlId='exampleForm.ControlTextarea1' className='w-50 px-3 mb-3'>
								<Form.Label>Plan terapii</Form.Label>
								<CreatableSelect
									name='codes'
									options={prepareOptions(codes)}
									isSearchable
									isMulti
									value={
										visit.codes
											? visit.codes.map((code) => {
													//console.log(code);
													return {value: code, label: code};
											  })
											: null
									}
									onChange={(value) => {
										if (value) {
											handleCodeChange(value);
										}
									}}
								/>
							</Form.Group>
						)}
						<Form.Group controlId='exampleForm.ControlTextarea1' className='w-50 px-3 mb-3'>
							<Form.Label>Zalecenia</Form.Label>
							<Form.Control
								as='textarea'
								rows={4}
								name='advisories'
								onChange={handleChange}
								value={visit.advisories}
							/>
						</Form.Group>
						<Form.Group controlId='exampleForm.ControlTextarea1' className='w-50 px-3 mb-3'>
							<Form.Label>Inne</Form.Label>
							<Form.Control
								as='textarea'
								rows={4}
								name='others'
								onChange={handleChange}
								value={visit.others}
							/>
						</Form.Group>
						<Form.Group controlId='formBasicPassword' className='w-50 px-3 mb-3'>
							<Form.Label className='required'>Cena</Form.Label>
							<Form.Control
								type='text'
								placeholder='Cena wizyty'
								name='price'
								value={visit.price}
								onChange={handleChange}
								pattern='^\d*(\.\d{0,2})?$'
							/>
						</Form.Group>
						<Form.Group controlId='custom-switch' className='d-flex align-items-center mt-3 w-100'>
							<Form.Check
								type='switch'
								id='custom-switch'
								checked={visit.confirmed}
								onChange={handleChange}
								name='confirmed'
							/>
							<Form.Label className='mx-3 d-block'>Pacjent potwierdził wizytę</Form.Label>
						</Form.Group>
						<Form.Group controlId='custom-switch-2' className='d-flex align-items-center mt-3 w-100'>
							<Form.Check
								type='switch'
								id='custom-switch-2'
								checked={visit.done}
								onChange={handleChange}
								name='done'
							/>
							<Form.Label className='mx-3 d-block'>Wizyta odbyta</Form.Label>
						</Form.Group>
						<Form.Group controlId='custom-switch-3' className='d-flex align-items-center mt-3 w-100'>
							<Form.Check
								type='switch'
								id='custom-switch-3'
								checked={visit.consultations}
								onChange={handleChange}
								name='consultations'
							/>
							<Form.Label className='mx-3 d-block'>Konsultacja</Form.Label>
						</Form.Group>
						<div className='d-flex justify-content-end align-items-center w-100'>
							<Button variant='success' type='submit' className='mt-4 mx-2 px-5'>
								Zapisz
							</Button>
						</div>
					</Form>
				</Card.Body>
			</Card>
		);
	} else {
		return <div></div>;
	}
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addNotification: (payload: string) => dispatch(notificationActions.add(payload)),
	};
};

export default connect(null, mapDispatchToProps)(AddVisit);
