import React, {useState} from 'react';
import logout from '../../adapters/auth/logout';
import {push} from 'connected-react-router';
import {connect} from 'react-redux';
import {Col, Nav} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
// COMPONENTS
import DatePicker from '../DatePicker/DatePicker';

import './Navbar.scss';

const Navbar = (props: any) => {
  let links = [{path: '/login', displayName: 'Zaloguj się', iconClass: ''}];

  const [isNavbarShown, setNavbarShow] = useState<boolean>(false);

  const currentPath = useLocation().pathname;

  if (props.user.id >= 0) {
    links = [
      //   { path: '/profile', displayName: 'Mój profil', iconClass: '' },
      //   { path: '/schedules', displayName: 'Harmonogram' },
      {
        path: '/patients',
        displayName: 'Pacjenci',
        iconClass: 'bi-people-fill',
      }, // Should be end
      {
        path: '/schedules',
        displayName: 'Harmonogram',
        iconClass: 'bi-clock-fill',
      },
      {
        path: '/mycalendar',
        displayName: 'Kalendarz',
        iconClass: 'bi-calendar-check-fill',
      },
      {
        path: '/users',
        displayName: 'Wszyscy użytkownicy',
        iconClass: 'bi-people',
      },
      {
        path: '/adduser',
        displayName: 'Dodaj użytkownika',
        iconClass: 'bi-person-plus-fill',
      },
      {
        path: '/usersSchedules',
        displayName: 'Harmonogramy użytkowników',
        iconClass: 'bi-clock-fill',
      },
      {
        path: '/calendars',
        displayName: 'Kalendarze użytkowników',
        iconClass: 'bi-calendar-check-fill',
      },
    ];
  }

  if (props.user.permission === 'admin') {
    links = [
      ...links,
      {
        path: '/raports',
        displayName: 'Raporty',
        iconClass: 'bi-briefcase-fill',
      },
      //       {
      //         path: '/schedules',
      //         displayName: 'Harmonogram',
      //         iconClass: 'bi-clock-fill',
      //       },
      //       {
      //         path: '/mycalendar',
      //         displayName: 'Kalendarz',
      //         iconClass: 'bi-calendar-check-fill',
      //       },
      //       {
      //         path: '/users',
      //         displayName: 'Wszyscy użytkownicy',
      //         iconClass: 'bi-people',
      //       },
      //       {
      //         path: '/adduser',
      //         displayName: 'Dodaj użytkownika',
      //         iconClass: 'bi-person-plus-fill',
      //       },
    ];
  }

  //   if (props.user.permission === 'admin' || props.user.permission === 'recepcja' || true) {
  //     links = [
  //       ...links,
  //       {
  //         path: '/usersSchedules',
  //         displayName: 'Harmonogramy użytkowników',
  //         iconClass: 'bi-clock-fill',
  //       },
  //       {
  //         path: '/calendars',
  //         displayName: 'Kalendarze użytkowników',
  //         iconClass: 'bi-calendar-check-fill',
  //       },
  //     ];
  //   }

  //   if (props.user.permission === 'fizjo') {
  //     links = [
  //       ...links,
  //       {
  //         path: '/mycalendar',
  //         displayName: 'Kalendarz',
  //         iconClass: 'bi-calendar-check-fill',
  //       },
  //       {
  //         path: '/schedules',
  //         displayName: 'Harmonogram',
  //         iconClass: 'bi-clock-fill',
  //       },
  //     ];
  //   }

  const handleLogout = () => {
    if (props.token.token) {
      logout();
      props.push('/login');
    }
  };

  const handleRedirect = (path: string): void => {
    props.push(path);
  };

  //console.log(process.env);
  return (
    <div className={`pro-navbar d-flex justify-content-between flex-column ${isNavbarShown ? '' : 'hidden'}`}>
      <div>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='color__omega'>Wersja: {process.env.REACT_APP_VERSION}</div>
          <button
            className='navbar__toggle-button color__omega text-right'
            style={{transform: `scale(${isNavbarShown ? '1' : '-1'})`}}
            onClick={() => setNavbarShow(!isNavbarShown)}
          >
            <div className='toggle-button__icon'>
              <i className='bi bi-chevron-double-left'></i>
            </div>
          </button>
        </div>
        <Nav className='w-100'>
          <ul className='navbar__list w-100'>
            {links.map((item, index) => (
              <li className='list__item' key={index}>
                <button
                  className={`item__button color__omega w-100 ${currentPath === item.path ? 'active' : ''}`}
                  aria-current='page'
                  name={item.path}
                  onClick={() => handleRedirect(item.path)}
                >
                  <div className='button__text'>{item.displayName}</div>
                  <div className='button__icon'>
                    <i className={`bi ${item.iconClass}`}></i>
                  </div>
                </button>
              </li>
            ))}
            {/* {props.user.id >= 0 && (
            <li className='list__item'>
              <button
                className='btn btn-secondary w-100 mt-4'
                aria-current='page'
                onClick={() => handleLogout()}
                name='/logout'
              >
                <div className='button__text'>Wyloguj się</div>
                <div className="button__icon">
                    <i className={`bi ${item.iconClass}`} ></i>
                </div>
              </button>
            </li>
          )} */}
          </ul>
        </Nav>
      </div>
      <div className='navbar__calendar' style={{opacity: isNavbarShown ? '1' : '0'}}>
        <DatePicker />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  token: state.token,
  user: state.user,
});

export default connect(mapStateToProps, {push})(Navbar);
