import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { IUser } from '../../redux/user/types';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import users from '../../adapters/user/users';
import AddUserForm from '../../components/Forms/AddUserForm';
import ChangePassword from '../../components/Forms/ChangePassword';

const Users = (props: any) => {
  const [usersData, setUsersData] = useState<IUser[]>();

  useEffect(() => {
    if (usersData === undefined) {
      users()
        .then((response) => {
          setUsersData(response as IUser[]);
        })
        .catch(() => {
          props.push('/');
        });
    }

    return () => {};
  }, []);

  return (
    <Row>
      <Col>
        <Accordion defaultActiveKey='0'>
          {usersData &&
            usersData?.map((user) => (
              <Card key={user.id} className='my-2'>
                <div className='w-100 d-flex justify-content-between align-items-center px-3 py-2'>
                  <span>
                    {user.lastname} {user.name} {user.email}
                  </span>
                  <Accordion.Toggle as={Button} eventKey={user.id.toString()}>
                    Więcej
                  </Accordion.Toggle>
                </div>
                <Accordion.Collapse eventKey={user.id.toString()}>
                  <Card.Body className='px-5'>
                    <AddUserForm
                      user={user}
                      title='Aktualizacja danych użytkownika'
                      buttonText='Aktualizuj dane użytkownika'
                    />
                    <Row className='pt-4'>
                      <ChangePassword id={user.id} />
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
        </Accordion>
      </Col>
    </Row>
  );
};

export default connect(null, { push })(Users);
