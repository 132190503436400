import React, {useEffect, useState} from 'react';
import {Row, Col, Accordion, Card, Button, Form} from 'react-bootstrap';
import patientsTreatments from '../../adapters/treatment/getTreatmentsByPatient';
import addTreatment from '../../adapters/treatment/addTreatment';
import {notificationActions} from '../../redux/notifications/index';
import {connect} from 'react-redux';
// COMPONENTS
import VistiListByTreatment from '../../components/VisitsList/VisitsListByTreatment';
import TreatmentForm from '../../components/TreatmentForm/TreatmentForm';
// TYPES
import {ITreatment} from '../../types/treatmentInterface';

export interface ITreatmentListProps {
	patientId: number;
	treatmentId?: number;
	addNotification: any;
}

const TreatmentList = (props: ITreatmentListProps) => {
	const [treatmentsList, setTreatmentsList] = useState<ITreatment[]>(new Array<ITreatment>());
	const [treatmentName, setTreatmentName] = useState<string>('');

	useEffect(() => {
		patientsTreatments(props.patientId)
			.then((result) => {
				setTreatmentsList(result as ITreatment[]);
			})
			.catch((e) => {
				//console.log(e);
			});
		return () => {};
	}, []);

	const updateList = (treatment: ITreatment) => {
		const sameTreatment = treatmentsList.filter((item) => item.id === treatment.id);

		if (sameTreatment.length > 0) {
			const newTreatments = treatmentsList.map((item) => {
				if (item.id === treatment.id) {
					//console.log(item.id, 'ITem id');
					return treatment;
				} else {
					return item;
				}
			});
			setTreatmentsList([...newTreatments]);
		} else {
			setTreatmentsList([...treatmentsList, {...treatment}]);
		}
	};

	const handelAddTreatment = (e: React.FormEvent) => {
		e.preventDefault();

		if (treatmentName) {
			addTreatment({name: treatmentName, patient_id: props.patientId})
				.then((res) => {
					props.addNotification(res.message);
					if (res.treatment) {
						updateList(res.treatment);
					}
					setTreatmentName('');
				})
				.catch((e) => {
					props.addNotification(e.message);
				});
		}
	};

	return (
		<Row>
			<Col sm={12}>
				<Form onSubmit={handelAddTreatment} className='d-flex w-100 justify-content-start my-3 align-items-end'>
					<Form.Group className='w-50'>
						<label>Nazwa leczenia</label>
						<Form.Control
							type='text'
							name='name'
							value={treatmentName}
							onChange={(e) => setTreatmentName(e.currentTarget.value)}
						/>
					</Form.Group>
					<button type='submit' className='btn btn-success mx-4'>
						Dodaj leczenie
					</button>
				</Form>
			</Col>
			<Col>
				<Accordion defaultActiveKey='0'>
					{treatmentsList.map((treatment) => (
						<Card key={treatment.id} className='my-2'>
							<div className='w-100 d-flex justify-content-between align-items-center px-3 py-2'>
								<span>
									<b>Leczenie:</b> {treatment.name}, <b>Status:</b>{' '}
									{treatment.status ? 'Zakończone' : 'Otwarte'}
								</span>
								<Accordion.Toggle as={Button} eventKey={treatment.id ? treatment.id.toString() : ''}>
									Więcej
								</Accordion.Toggle>
							</div>
							<Accordion.Collapse eventKey={treatment.id ? treatment.id.toString() : ''}>
								<Card.Body className='px-5'>
									<TreatmentForm treatment={treatment} notFetch={true} onUpdate={updateList} />
									<VistiListByTreatment treatmentId={treatment.id} patientId={props.patientId} />
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					))}
				</Accordion>
			</Col>
		</Row>
	);
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addNotification: (payload: string) => dispatch(notificationActions.add(payload)),
	};
};

export default connect(null, mapDispatchToProps)(TreatmentList);
