import {ITreatment} from './../../types/treatmentInterface';
import {INewTreatment} from '../../types/newTreatmentInterface';
import axios from '../xhr';

export interface IRegisterPatientResponse {
  status: number;
  message: string;
  treatment?: ITreatment;
  errors?: string[];
}

const addTreatment = (treatmentData: INewTreatment): Promise<IRegisterPatientResponse> => {
  const response = axios
    .post('/treatment', treatmentData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve({
          status: 200,
          message: 'Leczenie zostało pomyślnie dodane',
          treatment: response.data.treatment,
        });
      }
      if (response.status === 409) {
        return Promise.resolve({
          status: 409,
          message: response.data.message,
        });
      }
      if (response.status === 422) {
        return Promise.resolve({
          status: 422,
          message: 'Nie poprawne pola',
          errors: response.data.errors,
        });
      }

      return Promise.reject({
        status: 499,
        message: 'Nie znany błąd',
      });
    })
    .catch((e) => {
      return Promise.reject({
        status: 500,
        message: 'Wewnętrzny błąd serwera',
      });
    });

  return response;
};

export default addTreatment;
